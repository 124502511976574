<template>
  <div>
    <!--
    <div
      v-permission="'orders.create'"
      class="flex flex-wrap-reverse items-center">
      <div
        class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary"
        @click="addNew">
        <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
        <span class="ml-2 text-base text-primary">{{ $t('action.new_order') }}</span>
      </div>
    </div>
    -->
    <Grid
      :service="service"
      route_name="orders"
      order_column="created_at"
      direction="desc">
      <template v-slot:gridThead>
        <vs-th key="id" sort-key="id">ID</vs-th>
        <vs-th key="created_at_fmt" sort-key="created_at">{{ $t('order.created') }}</vs-th>
        <vs-th key="status" sort-key="status">{{ $t('order.status') }}</vs-th>
        <vs-th key="amount_total" sort-key="amount_total">{{ $t('order.total') }}</vs-th>
      </template>
      <template v-slot:gridData="props">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in props.data">
          <vs-td width="5%" key="id">
            <div> {{ tr.id }} </div>
          </vs-td>
          <vs-td width="50%" key="created_at_fmt">
            <div> {{ tr.created_at_fmt }}</div>
          </vs-td>
          <vs-td width="15%" key="status">
            <vs-chip transparent :color="getStatusColor(_.get(tr, 'status'))" class="text-base">{{formatStatus(_.get(tr, 'status')) }}</vs-chip>
          </vs-td>
          <vs-td width="20%" key="amount_total" class="text-right">
            <span> {{formatValue(_.get(tr, 'amount_total')) }}</span>
          </vs-td>
          <vs-td width="10%">
            <div class="flex flex-row justify-center gap-1">
              <feather-icon
                v-permission="'orders.detail'"
                v-tooltip="$t('order.detail')"
                icon="EyeIcon"
                svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-warning"
                @click="detailOrder(props.data[indextr].id)"/>
              <feather-icon
                  v-if="showPayAction(props.data[indextr])"
                  v-permission="'orders.pay'"
                v-tooltip="$t('order.pay')"
                  icon="DollarSignIcon"
                  svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-primary"
                  @click="payOrder(props.data[indextr].id)"/>
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </Grid>
  </div>
</template>

<script>

import OrderService from '@/services/api/OrderService'

import { currencyFormatter } from '@/util/Util'

export default {
  data: () => ({
    selected: [],
    service: null
  }),
  methods: {
    addNew() {
      this.$router.push('/orders/create')
    },
    formatValue(value){
      return currencyFormatter(value)
    },
    formatStatus(stt){
      return this.$t('order.status_.'+stt)
    },
    getStatusColor(stt){
      switch (stt) {
        case ('DO'):
          return 'default'
          break;
        case ('PE'):
          return 'warning'
          break;
        case ('PA'):
          return 'success'
          break;
        case ('CA'):
          return 'danger'
          break;
        default:
          break;
      }
    },
    showPayAction(item){
      return (item.status == 'DO' || item.status == 'PE')
    },
    payOrder(orderId){
      this.$router.push(`/orders/${orderId}/payment`)
    },
    detailOrder(orderId){
      this.$router.push(`/orders/${orderId}/detail`)
    }
  },
  beforeMount() {
    this.service = OrderService.build(this.$vs)
  }
}
</script>
