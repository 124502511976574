var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Grid", {
        attrs: {
          service: _vm.service,
          route_name: "orders",
          order_column: "created_at",
          direction: "desc",
        },
        scopedSlots: _vm._u([
          {
            key: "gridThead",
            fn: function () {
              return [
                _c("vs-th", { key: "id", attrs: { "sort-key": "id" } }, [
                  _vm._v("ID"),
                ]),
                _c(
                  "vs-th",
                  {
                    key: "created_at_fmt",
                    attrs: { "sort-key": "created_at" },
                  },
                  [_vm._v(_vm._s(_vm.$t("order.created")))]
                ),
                _c(
                  "vs-th",
                  { key: "status", attrs: { "sort-key": "status" } },
                  [_vm._v(_vm._s(_vm.$t("order.status")))]
                ),
                _c(
                  "vs-th",
                  {
                    key: "amount_total",
                    attrs: { "sort-key": "amount_total" },
                  },
                  [_vm._v(_vm._s(_vm.$t("order.total")))]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "gridData",
            fn: function (props) {
              return _vm._l(props.data, function (tr, indextr) {
                return _c(
                  "vs-tr",
                  { key: indextr, attrs: { data: tr } },
                  [
                    _c("vs-td", { key: "id", attrs: { width: "5%" } }, [
                      _c("div", [_vm._v(" " + _vm._s(tr.id) + " ")]),
                    ]),
                    _c(
                      "vs-td",
                      { key: "created_at_fmt", attrs: { width: "50%" } },
                      [_c("div", [_vm._v(" " + _vm._s(tr.created_at_fmt))])]
                    ),
                    _c(
                      "vs-td",
                      { key: "status", attrs: { width: "15%" } },
                      [
                        _c(
                          "vs-chip",
                          {
                            staticClass: "text-base",
                            attrs: {
                              transparent: "",
                              color: _vm.getStatusColor(
                                _vm._.get(tr, "status")
                              ),
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.formatStatus(_vm._.get(tr, "status")))
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "vs-td",
                      {
                        key: "amount_total",
                        staticClass: "text-right",
                        attrs: { width: "20%" },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.formatValue(_vm._.get(tr, "amount_total"))
                              )
                          ),
                        ]),
                      ]
                    ),
                    _c("vs-td", { attrs: { width: "10%" } }, [
                      _c(
                        "div",
                        { staticClass: "flex flex-row justify-center gap-1" },
                        [
                          _c("feather-icon", {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: "orders.detail",
                                expression: "'orders.detail'",
                              },
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.$t("order.detail"),
                                expression: "$t('order.detail')",
                              },
                            ],
                            attrs: {
                              icon: "EyeIcon",
                              svgClasses:
                                "h-5 w-5 mb-1 mr-3 stroke-current text-warning",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.detailOrder(props.data[indextr].id)
                              },
                            },
                          }),
                          _vm.showPayAction(props.data[indextr])
                            ? _c("feather-icon", {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: "orders.pay",
                                    expression: "'orders.pay'",
                                  },
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.$t("order.pay"),
                                    expression: "$t('order.pay')",
                                  },
                                ],
                                attrs: {
                                  icon: "DollarSignIcon",
                                  svgClasses:
                                    "h-5 w-5 mb-1 mr-3 stroke-current text-primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.payOrder(props.data[indextr].id)
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                )
              })
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }